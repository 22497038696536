import {
  faFilter,
  faFilterCircleXmark,
  faArrowsRotate,
  faCalendar,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState, useEffect, useContext, useMemo } from 'react';
import { CardTitle, Menu, CardFiltersLayout, Card, Tabs, ITab } from '../../components';
import { ServiceDetailPage } from '../service-details';
import { getScheduledServices, recurringExpiresInNext30Days } from '../../fetch';
import {
  IDateRange,
  IScheduledService,
  ISimpleDateRange,
  ServicePodType,
  ServiceTypeTab,
} from '../../models';
import { ScheduledServicesFilters } from './ScheduledServicesFilters';
import clsx from 'clsx';
import { format, subDays } from 'date-fns';
import { ConfirmModal } from './confirm-modal';
import { ScheduledServicesDataGrid } from './scheduled-services-data-grid';
import { GridSortDirection } from '@mui/x-data-grid';
import { MAINTENANCE_FIELD_REPORT_USER_GROUP, dateStringNull, formatDate } from '../../helpers';
import { RecurringServicesDataGrid } from './recurring-services-data-grid';
import { Paths } from '../../constants';
import { ESearchParams, SearchParamsContext } from '../../context';
import { FieldReportDataGrid } from './field-report-data-grid';
import qs from 'querystring';

export interface IScheduledServices {
  dateRange?: ISimpleDateRange;
  accountId?: string;
  redirect?: string;
  isFieldReport?: boolean;
  hasQueryParamFiltering?: boolean;
  selectedUserFilter?: string;
  title?: string;
  noResultsText?: string;
  siteId?: string;
  onNavigation?: (route: string) => unknown;
  isCollapsible?: boolean;
  initialExpand?: boolean;
  isExpanded?: boolean;
  isEditable?: boolean;
  dateLinkModal?: boolean;
  currentStartDatePast30Days?: boolean;
  isSitePage?: boolean;
  showNonClickableAttributes?: boolean;
  showRecurringTab?: boolean;
  parentRecurringService?: string;
  isHomePage?: boolean;
}

const localStorageUserGroupId = localStorage?.getItem(MAINTENANCE_FIELD_REPORT_USER_GROUP);
const sub30Days = subDays(new Date(), 30);
const initialCurrentStartDate = sub30Days;
const initialCompletedEndDate = new Date();

export const ScheduledServices: FC<IScheduledServices> = ({
  dateRange,
  accountId,
  redirect = Paths.maintenance.url,
  isFieldReport,
  selectedUserFilter,
  title,
  siteId,
  onNavigation,
  isCollapsible = false,
  initialExpand = false,
  isExpanded,
  isEditable = true,
  dateLinkModal = true,
  currentStartDatePast30Days = false,
  isSitePage = false,
  showNonClickableAttributes,
  showRecurringTab = true,
  parentRecurringService,
  hasQueryParamFiltering = true,
  isHomePage,
}) => {
  const {
    setSearchParams,
    queryParams,
    paramSiteId,
    paramServiceDefId,
    paramStatus,
    paramStartDate,
    paramEndDate,
    paramUserIds,
    paramUserGroupId,
    paramTab,
    hasSearchParamValues,
  } = useContext(SearchParamsContext);
  const isSmMobile = useMediaQuery('(max-width: 600px)');

  const { enqueueSnackbar } = useSnackbar();
  const [isShowingFilters, setIsShowingFilters] = useState(
    hasSearchParamValues ? true : isFieldReport ?? false
  );
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>({
    startDate: paramStartDate ? new Date(paramStartDate) : initialCurrentStartDate ?? null,
    endDate: paramEndDate ? new Date(paramEndDate) : dateRange?.selection?.endDate ?? null,
    key: 'selection',
    inputValue: '',
  });
  const [selectedSite, setSelectedSite] = useState<string>(paramSiteId ?? '');
  const [selectedStatus, setSelectedStatus] = useState<string>(
    paramStatus ? paramStatus : isFieldReport ? 'All' : 'O'
  );
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<string>(
    paramUserIds ? paramUserIds : ''
  );
  const [selectedServiceType, setSelectedServiceType] = useState<string>(
    paramServiceDefId ? paramServiceDefId : ''
  );
  const [selectedUserGroup, setSelectedUserGroup] = useState<string>(
    isFieldReport ? paramUserGroupId ?? localStorageUserGroupId ?? '' : ''
  );

  const [selectedTab, setSelectedTab] = useState<ServiceTypeTab | string>(
    paramTab ? paramTab : ServiceTypeTab.OPEN
  );
  const [defaultUserGroup, setDefaultUserGroup] = useState<string>('');
  const [hasAppliedFilters, setHasAppliedFilters] = useState(hasSearchParamValues ? true : false);
  const [confirmService, setConfirmService] = useState<IScheduledService | null>(null);
  const [filters, setFilters] = useState<{
    serviceDefId?: number | string;
    status?: string;
    startDate?: dateStringNull;
    endDate?: dateStringNull;
    userIds?: string;
    siteId?: string;
    userGroupId?: string;
  } | null>({});

  const [currentServiceOption, setServiceOption] = useState<{
    currentServiceId: string | null;
    optionType: ServicePodType | null;
  }>({
    currentServiceId: null,
    optionType: null,
  });
  const [isLoadingServices, setLoadingServices] = useState(false);
  const [internalUserFilter, setInternalUserFilter] = useState(selectedUserFilter ?? undefined);

  useEffect(() => {
    if (paramSiteId !== null) {
      setSelectedSite(paramSiteId);
    }
    if (paramServiceDefId !== null) {
      setSelectedServiceType(paramServiceDefId);
    }
    if (paramStatus !== null) {
      setSelectedStatus(paramStatus);
    }
    if (paramUserIds !== null) {
      setSelectedAssignedTo(paramUserIds);
    }
    if (paramUserGroupId !== null) {
      setSelectedUserGroup(paramUserGroupId);
    }
    if (paramStartDate !== null) {
      setSelectedDateRange({
        ...selectedDateRange,
        startDate: paramStartDate ? new Date(paramStartDate) : null,
        endDate: paramEndDate ? new Date(paramEndDate!) : null,
      });
    }
    if (paramEndDate !== null) {
      setSelectedDateRange({
        ...selectedDateRange,
        startDate: paramStartDate ? new Date(paramStartDate) : null,
        endDate: paramEndDate ? new Date(paramEndDate!) : null,
      });
    }
    if (paramTab !== null) {
      setSelectedTab(paramTab);
    }
    if (hasSearchParamValues && !hasAppliedFilters && !isShowingFilters) {
      setIsShowingFilters(true);
      setHasAppliedFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paramSiteId,
    paramServiceDefId,
    paramStatus,
    paramStartDate,
    paramEndDate,
    paramUserIds,
    paramUserGroupId,
    paramTab,
    hasSearchParamValues,
    hasAppliedFilters,
    isShowingFilters,
  ]);

  const siteAndAccountIdParams = useMemo(() => {
    if (!siteId && !accountId) {
      return '';
    }
    const params = {
      siteId: siteId ?? '',
      accountId: accountId ?? '',
    };
    return qs.stringify(params) + '&';
  }, [siteId, accountId]);

  const fetchServices = async ({
    sortColumn,
    beforeItemId,
    sortDirection,
    afterItemId,
    perPage,
    isFirstPage,
    isLastPage,
    searchParams,
  }: {
    sortColumn?: string;
    beforeItemId?: number;
    sortDirection?: GridSortDirection;
    afterItemId?: number;
    perPage: number;
    isFirstPage?: boolean;
    isLastPage?: boolean;
    searchParams?: URLSearchParams;
  }) => {
    let arrSearchParamsValues = [];
    // convert the search params to an array of values
    if (searchParams) {
      arrSearchParamsValues = Array.from(searchParams.values()).filter(
        p => !p.includes('redirect')
      );
    }
    setLoadingServices(true);
    const getStartDate = () => {
      if (searchParams && searchParams?.get(ESearchParams.startDate)) {
        return format(
          new Date(searchParams?.get(ESearchParams.startDate)!),
          "yyyy-MM-dd'T'HH:mm:ss"
        );
      } else if (!!selectedDateRange?.startDate) {
        return format(selectedDateRange?.startDate, "yyyy-MM-dd'T'HH:mm:ss");
      }
      return undefined;
    };
    const getEndDate = () => {
      if (searchParams && searchParams?.get(ESearchParams.endDate)) {
        return format(new Date(searchParams?.get(ESearchParams.endDate)!), "yyyy-MM-dd'T'HH:mm:ss");
      } else if (!!selectedDateRange?.endDate) {
        return format(selectedDateRange?.endDate, "yyyy-MM-dd'T'HH:mm:ss");
      }
      return undefined;
    };
    let params = {
      startDate: getStartDate(),
      endDate: getEndDate(),
      sortBy: sortColumn,
      serviceDefId:
        selectedServiceType === 'All' || !selectedServiceType ? undefined : selectedServiceType,
      status: selectedStatus === 'All' || !selectedStatus ? ['O, H, C, P'] : selectedStatus,
      sortDirection: sortDirection ? sortDirection : 'asc',
      perPage,
      accountId: accountId ?? '',
      userIds: internalUserFilter ?? selectedAssignedTo,
      userGroupId: selectedUserGroup === 'all' ? '' : selectedUserGroup ?? '',
      siteId: siteId ?? selectedSite ?? '',
      first: isFirstPage ?? false,
      last: isLastPage ?? false,
      recurringServiceId: parentRecurringService ?? '',
    };
    if (arrSearchParamsValues?.length > 0) {
      params = {
        ...params,
        sortBy:
          searchParams?.get(ESearchParams.sort) !== null
            ? searchParams?.get(ESearchParams.sort)!
            : params.sortBy,
        serviceDefId:
          searchParams?.get(ESearchParams.serviceDefId) !== null
            ? searchParams?.get(ESearchParams.serviceDefId)!
            : params.serviceDefId,
        status:
          searchParams?.get(ESearchParams.status) !== null
            ? searchParams?.get(ESearchParams.status)!
            : params.status,
        sortDirection: (searchParams?.get(ESearchParams.sortDirection) !== null
          ? searchParams?.get(ESearchParams.sortDirection)!
          : params.sortDirection) as 'asc' | 'desc',
        userIds:
          searchParams?.get(ESearchParams.userIds) !== null
            ? searchParams?.get(ESearchParams.userIds)!
            : params.userIds,
        userGroupId:
          searchParams?.get(ESearchParams.userGroupId) !== null
            ? searchParams?.get(ESearchParams.userGroupId)!
            : params.userGroupId,
        siteId:
          searchParams?.get(ESearchParams.siteId) !== null
            ? searchParams?.get(ESearchParams.siteId)!
            : params.siteId,
      };
    }
    if (beforeItemId) {
      // @ts-ignore
      params.before = beforeItemId;
    }
    if (afterItemId) {
      // @ts-ignore
      params.after = afterItemId;
    }
    try {
      const res = await getScheduledServices(params);
      return {
        rows: res.data,
        rowCount: res.totalCount,
      };
    } catch (error) {
      enqueueSnackbar(`Error loading scheduled services, please try again.`, {
        variant: 'error',
      });
      throw error;
    } finally {
      setLoadingServices(false);
    }
  };

  useEffect(() => {
    if (isFieldReport) return;

    const fetchDataToDisplayCorrespondingTab = async () => {
      if (await recurringExpiresInNext30Days(siteId)) {
        setSelectedTab(ServiceTypeTab.RECURRING);
        if (hasQueryParamFiltering) {
          setSearchParams({
            [ESearchParams.tab]: ServiceTypeTab.RECURRING,
          });
        }
      }
    };

    fetchDataToDisplayCorrespondingTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, isFieldReport]);

  useEffect(() => {
    if (selectedUserFilter !== internalUserFilter) {
      setInternalUserFilter(selectedUserFilter);
    }
  }, [selectedUserFilter, internalUserFilter]);

  return (
    <>
      <Card>
        <CardTitle
          title={title ? title : 'Scheduled Services'}
          mobileWrap
          marginBottom={0}
          action={
            <>
              <Button
                onClick={() => setIsShowingFilters(!isShowingFilters)}
                className={clsx('print--none')}
                color="secondary"
                size="small"
                disabled={isLoadingServices}
                startIcon={
                  <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                }
                data-testid="filters-button"
              >
                Filters
              </Button>

              <Menu
                color="secondary"
                disableMargin
                isDisabled={isLoadingServices}
                startIcon={faPlusCircle}
                buttonText="Add Service"
                buttonProps={{
                  fullWidth: isSmMobile,
                }}
                options={[
                  {
                    icon: faCalendar,
                    link: `/services/maintenance/new${
                      redirect && queryParams.length > 0
                        ? `?${siteAndAccountIdParams}redirect=${encodeURIComponent(
                            `${redirect}?${queryParams}`
                          )}`
                        : `?${siteAndAccountIdParams}redirect=${redirect}`
                    }`,
                    text: 'New Single Service',
                  },
                  {
                    icon: faArrowsRotate,
                    link: `/services/maintenance/recurring${
                      redirect && queryParams.length > 0
                        ? `?${siteAndAccountIdParams}redirect=${encodeURIComponent(
                            `${redirect}?${queryParams}`
                          )}`
                        : `?${siteAndAccountIdParams}redirect=${redirect}`
                    }`,
                    text: 'New Recurring Service',
                  },
                ].map(o => {
                  if (!onNavigation) {
                    return o;
                  }
                  return {
                    ...o,
                    link: null,
                    onClick: () => onNavigation(o.link),
                  };
                })}
              />
            </>
          }
          withExpand={isCollapsible}
          initialExpand={initialExpand}
          overrideExpand={isExpanded}
        >
          <CardFiltersLayout isOpen={isShowingFilters}>
            <ScheduledServicesFilters
              initialDateRange={dateRange}
              setSelectedDateRange={setSelectedDateRange}
              selectedDateRange={selectedDateRange}
              hasAppliedFilters={hasAppliedFilters}
              setHasAppliedFilters={setHasAppliedFilters}
              selectedServiceType={selectedServiceType}
              setSelectedServiceType={setSelectedServiceType}
              isLoading={isLoadingServices}
              setSelectedSite={accountId ? undefined : setSelectedSite}
              selectedSite={selectedSite}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              hasQueryParamFiltering={hasQueryParamFiltering}
              applyFilters={(clearFilters?: boolean, userGroupId?: string) => {
                if (clearFilters) {
                  setFilters({
                    serviceDefId: '',
                    status: selectedTab === ServiceTypeTab.OPEN ? 'O' : 'C',
                    startDate: dateRange?.selection?.startDate?.toUTCString(),
                    endDate: dateRange?.selection?.endDate?.toUTCString(),
                    siteId: '',
                    userIds: '',
                    userGroupId: defaultUserGroup ?? '',
                  });
                  if (hasQueryParamFiltering) {
                    setSearchParams({
                      [ESearchParams.serviceDefId]: '',
                      [ESearchParams.status]: selectedTab === ServiceTypeTab.OPEN ? 'O' : 'C',
                      [ESearchParams.startDate]: '',
                      [ESearchParams.endDate]: '',
                      [ESearchParams.siteId]: '',
                      [ESearchParams.userIds]: '',
                      [ESearchParams.userGroupId]: defaultUserGroup || '',
                    });
                  }
                } else {
                  setFilters({
                    ...filters,
                    serviceDefId: selectedServiceType,
                    status: selectedStatus,
                    startDate: selectedDateRange?.startDate,
                    endDate: selectedDateRange?.endDate,
                    siteId: selectedSite,
                    userIds: internalUserFilter ?? selectedAssignedTo,
                    userGroupId: userGroupId ?? selectedUserGroup,
                  });
                  if (hasQueryParamFiltering) {
                    setSearchParams({
                      [ESearchParams.siteId]: selectedSite.length > 0 ? selectedSite : undefined,
                      [ESearchParams.serviceDefId]:
                        selectedServiceType.length > 0 ? selectedServiceType : undefined,
                      [ESearchParams.status]:
                        selectedStatus.length > 0 ? selectedStatus : undefined,
                      [ESearchParams.startDate]: selectedDateRange?.startDate
                        ? formatDate(selectedDateRange?.startDate!)
                        : undefined,
                      [ESearchParams.endDate]: selectedDateRange?.endDate
                        ? formatDate(selectedDateRange?.endDate!)
                        : undefined,
                      [ESearchParams.userIds]: internalUserFilter
                        ? internalUserFilter
                        : selectedAssignedTo
                        ? selectedAssignedTo
                        : undefined,
                      [ESearchParams.userGroupId]: userGroupId
                        ? userGroupId
                        : selectedUserGroup
                        ? selectedUserGroup
                        : undefined,
                    });
                  }
                }
              }}
              setSelectedAssignedTo={setSelectedAssignedTo}
              selectedAssignedTo={internalUserFilter ?? selectedAssignedTo}
              selectedTab={selectedTab}
              selectedUserGroup={selectedUserGroup}
              defaultUserGroup={defaultUserGroup}
              setDefaultUserGroup={setDefaultUserGroup}
              setSelectedUserGroup={setSelectedUserGroup}
              isFieldReport={isFieldReport}
              isRecurringServiceDetail={parentRecurringService ? true : false}
            />
          </CardFiltersLayout>
          {selectedTab && !isFieldReport && (
            <Box mt={isCollapsible ? -1.5 : undefined}>
              <Tabs
                id="maintenance-service-tabs"
                selectedTab={selectedTab}
                setSelectedTab={async val => {
                  // Reset filters on tab switch
                  if (hasQueryParamFiltering) {
                    setSearchParams({
                      [ESearchParams.tab]: val,
                      [ESearchParams.status]: val,
                      // sometimes the redirect query param shows up and needs to be removed when tab changes
                      redirect: undefined,
                    });
                  }
                  if (!dateRange) {
                    setSelectedDateRange({
                      startDate:
                        currentStartDatePast30Days && val === 'O'
                          ? initialCurrentStartDate
                          : !currentStartDatePast30Days && val === 'O'
                          ? null
                          : initialCurrentStartDate,
                      endDate: val === 'O' ? null : initialCompletedEndDate,
                      key: 'selection',
                      inputValue: '',
                    });
                  }
                  setSelectedTab(val as ServiceTypeTab);
                  setSelectedStatus(val as ServiceTypeTab);
                }}
                tabs={
                  [
                    {
                      key: ServiceTypeTab.OPEN,
                      value: ServiceTypeTab.OPEN,
                      title: 'Current',
                      disabled: isLoadingServices,
                    },
                    {
                      key: ServiceTypeTab.CLOSED,
                      value: ServiceTypeTab.CLOSED,
                      title: 'Completed',
                      disabled: isLoadingServices,
                    },
                    showRecurringTab && {
                      key: ServiceTypeTab.RECURRING,
                      value: ServiceTypeTab.RECURRING,
                      title: 'Recurring',
                      disabled: isLoadingServices,
                    },
                  ].filter(Boolean) as ITab[]
                }
              />
            </Box>
          )}
          <Box mt={1}>
            {(selectedTab === ServiceTypeTab.OPEN || selectedTab === ServiceTypeTab.CLOSED) && (
              <>
                {isFieldReport ? (
                  <FieldReportDataGrid
                    setConfirmService={setConfirmService}
                    dateLinkModal={dateLinkModal}
                    redirect={
                      queryParams.length > 0
                        ? encodeURIComponent(`${redirect}?${queryParams}`)
                        : redirect
                    }
                    accountId={accountId}
                    setServiceOption={setServiceOption}
                    selectedTab={selectedTab}
                    isEditable={isEditable}
                    showNonClickableAttributes={
                      isSitePage && selectedTab === ServiceTypeTab.CLOSED
                        ? false
                        : showNonClickableAttributes
                    }
                    internalUserFilter={internalUserFilter}
                    selectedDateRange={selectedDateRange}
                    filters={filters}
                    selectedUserGroup={selectedUserGroup}
                    fetchServices={fetchServices}
                    dateRange={dateRange}
                    setSelectedDateRange={setSelectedDateRange}
                  />
                ) : (
                  <ScheduledServicesDataGrid
                    setConfirmService={setConfirmService}
                    dateLinkModal={dateLinkModal}
                    redirect={
                      queryParams.length > 0
                        ? encodeURIComponent(`${redirect}?${queryParams}`)
                        : redirect
                    }
                    accountId={accountId}
                    setServiceOption={setServiceOption}
                    selectedTab={selectedTab}
                    isEditable={isEditable}
                    isSitePage={isSitePage}
                    showNonClickableAttributes={
                      isSitePage && selectedTab === ServiceTypeTab.CLOSED
                        ? false
                        : showNonClickableAttributes
                    }
                    fetchServices={fetchServices}
                    filters={filters}
                    dateRange={isHomePage ? dateRange : undefined}
                    setSelectedDateRange={setSelectedDateRange}
                    selectedDateRange={selectedDateRange}
                    setFilters={setFilters}
                    hasQueryParamFiltering={hasQueryParamFiltering}
                  />
                )}
              </>
            )}
            {selectedTab === ServiceTypeTab.RECURRING && (
              <RecurringServicesDataGrid
                redirect={redirect}
                siteId={siteId ?? selectedSite}
                serviceDefId={selectedServiceType}
                selectedEndDate={selectedDateRange?.endDate?.toISOString()}
                selectedStartDate={selectedDateRange?.startDate?.toISOString()}
                selectedAssignedTo={selectedAssignedTo}
                isEditable={isEditable}
                filters={filters}
                queryParams={queryParams}
                hasQueryParamFiltering={hasQueryParamFiltering}
                shouldUseSearchParams={hasQueryParamFiltering}
              />
            )}
          </Box>
        </CardTitle>
      </Card>
      <ConfirmModal
        isOpen={!!confirmService}
        handleClose={() => setConfirmService(null)}
        confirmService={confirmService}
        redirect={
          queryParams.length > 0 ? encodeURIComponent(`${redirect}?${queryParams}`) : redirect
        }
      />
      {!!currentServiceOption?.currentServiceId && (
        <ServiceDetailPage
          isModal
          isModalOpen={true}
          handleModalClose={() =>
            setServiceOption({
              optionType: null,
              currentServiceId: null,
            })
          }
          podType={currentServiceOption?.optionType}
          currentServiceId={currentServiceOption?.currentServiceId}
        />
      )}
    </>
  );
};

import { FC, useCallback, useContext, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import {
  GridDataFetcher,
  Link,
  ServerSideDataGrid,
  ExternalLink,
  useDataGrid,
} from '../../components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { IRecurringService, IScheduledService } from '../../models';
import { ServicesActionButton } from './services-actions-button';
import { formatDate, getLegacyUrl } from '../../helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getRecurringServices } from '../../fetch';
import { useSnackbar } from 'notistack';
import { BrandingContext } from '../../context/branding-context';
import { ESearchParams } from '../../context';

interface RecurringServicesDataGridProps {
  redirect?: string;
  isEditable?: boolean;
  siteId?: string;
  serviceDefId?: string;
  selectedSite?: string;
  selectedEndDate?: string | null;
  selectedStartDate?: string | null;
  selectedAssignedTo?: string;
  hasQueryParamFiltering?: boolean;
  filters?: any;
  queryParams?: any;
  shouldUseSearchParams?: boolean;
}

export const RecurringServicesDataGrid: FC<RecurringServicesDataGridProps> = ({
  redirect,
  isEditable,
  siteId,
  serviceDefId,
  selectedEndDate,
  selectedStartDate,
  selectedAssignedTo,
  hasQueryParamFiltering,
  filters,
  queryParams,
  shouldUseSearchParams,
}) => {
  const { v2Customers } = useFlags();
  const legacyUrl = getLegacyUrl?.();
  const { enqueueSnackbar } = useSnackbar();
  const { isPoolService } = useContext(BrandingContext);

  const recurringServicesDataFetcher: GridDataFetcher<IRecurringService, number> = useCallback(
    async ({ sortColumn, sortDirection, searchParams }) => {
      let arrSearchParamsValues = [];
      // convert the search params to an array of values
      if (searchParams) {
        arrSearchParamsValues = Array.from(searchParams.values()).filter(
          p => !p.includes('redirect')
        );
      }
      let params = {
        initialDate: selectedStartDate,
        endDate: selectedEndDate,
        sortBy: sortColumn,
        serviceDefId,
        sortDirection: sortDirection ? sortDirection : 'asc',
        userId: selectedAssignedTo,
        siteId: siteId,
      };
      if (arrSearchParamsValues?.length > 0) {
        params = {
          ...params,
          initialDate:
            searchParams?.get(ESearchParams.startDate) !== null
              ? searchParams?.get(ESearchParams.startDate)!
              : params.initialDate,
          sortBy:
            searchParams?.get(ESearchParams.sort) !== null
              ? searchParams?.get(ESearchParams.sort)!
              : params.sortBy,
          serviceDefId:
            searchParams?.get(ESearchParams.serviceDefId) !== null
              ? searchParams?.get(ESearchParams.serviceDefId)!
              : params.serviceDefId,

          sortDirection: (searchParams?.get(ESearchParams.sortDirection) !== null
            ? searchParams?.get(ESearchParams.sortDirection)!
            : params.sortDirection) as 'asc' | 'desc',
          userId:
            searchParams?.get(ESearchParams.userId) !== null
              ? searchParams?.get(ESearchParams.userId)!
              : params.userId,
          siteId:
            searchParams?.get(ESearchParams.siteId) !== null
              ? searchParams?.get(ESearchParams.siteId)!
              : params.siteId,
        };
      }
      try {
        const res = await getRecurringServices(params);
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading recurring services, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    !isPoolService || !hasQueryParamFiltering
      ? [filters]
      : [
          isPoolService || hasQueryParamFiltering ? queryParams : undefined, // eslint-disable-line
        ]
  );

  const {
    rows: recurringServices,
    page: recurringPage,
    pageSize: recurringPerPage,
    rowCount: recurringServicesCount,
    sortModel: recurringSortModel,
    onSortModelChange: onRecurringSortModelChange,
    onPageChange: onRecurringPageChange,
    onPageSizeChange: onRecurringPageSizeChange,
    isLoading,
  } = useDataGrid<IRecurringService, number>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: `recurring-services-grid`,
      sortColumn: 'initialDate',
      sortDirection: 'asc',
    },
    dataFetcher: recurringServicesDataFetcher,
    shouldUseSearchParams,
  });

  const columns = useMemo(() => {
    return [
      {
        headerName: 'Site',
        field: 'siteDescription',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRecurringService>) => {
          const childComponent = (
            <Tooltip placement="bottom" title={`View customer's site`}>
              <span>{params?.row.siteDescription}</span>
            </Tooltip>
          );
          return v2Customers ? (
            <Link
              to={`/customers/${params?.row.accountId}?activeTab=sites&siteId=${params?.row.siteId}`}
            >
              {childComponent}
            </Link>
          ) : (
            <ExternalLink to={`${legacyUrl}/Office/Site/View/${params?.row.siteId}`}>
              {childComponent}
            </ExternalLink>
          );
        },
      },
      {
        field: 'serviceType',
        headerName: 'Type',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'repeatLink',
        headerName: 'Repeat Link',
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          return (
            original.frequency && (
              <Link
                to={`/services/maintenance/recurring/${original.recurringServiceId}${
                  redirect ? `?redirect=${redirect}` : ''
                }`}
              >
                <Tooltip title={`Edit recurring appointment schedule`} placement="bottom">
                  <span>{original.frequency}</span>
                </Tooltip>
              </Link>
            )
          );
        },
      },
      {
        field: 'userName',
        headerName: 'Tech',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: 'initialDate',
        headerName: 'Initial Date',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        maxWidth: 150,
        renderCell: (params: GridRenderCellParams<IRecurringService>) => {
          const { row: original } = params;
          return original.initialDate ? formatDate(original.initialDate) : '';
        },
      },
      {
        field: 'endDate',
        headerName: 'End Date',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        maxWidth: 150,
        renderCell: (params: GridRenderCellParams<IRecurringService>) => {
          const { row: original } = params;
          return original.endDate ? formatDate(original.endDate) : '';
        },
      },
      isEditable && {
        headerName: '',
        field: 'actions',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        width: 60,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          return <ServicesActionButton service={original} redirect={redirect} />;
        },
      },
    ].filter(Boolean);
  }, [redirect, isEditable, v2Customers, legacyUrl]);

  return (
    <ServerSideDataGrid
      autoHeight
      getRowId={(row: IRecurringService) => row.recurringServiceId}
      columns={columns as any[]}
      rows={recurringServices}
      rowCount={recurringServicesCount}
      page={recurringPage}
      pageSize={recurringPerPage}
      onPageChange={onRecurringPageChange}
      onPageSizeChange={onRecurringPageSizeChange}
      sortModel={recurringSortModel}
      onSortModelChange={onRecurringSortModelChange}
      loading={isLoading}
      hasMobileLayout
    />
  );
};

import { FC, SetStateAction, Dispatch, useEffect, useState, useContext } from 'react';
import { Grid, Box, TextField, MenuItem, InputAdornment, Button, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IDateRange,
  IListUser,
  IPaginatedResponse,
  IServiceDefinition,
  ISimpleDateRange,
  ISiteSimple,
  IUserGroup,
  ServiceTypeTab,
} from '../../models';
import { getServiceDefinitions, getUserGroups, getUsers } from '../../fetch';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { SelectAsyncInput, SiteAutocomplete } from '../../components';
import { SearchParamsContext, UserContext } from '../../context';
import { MAINTENANCE_FIELD_REPORT_USER_GROUP, checkForEnterKey } from '../../helpers';

interface IScheduledServicesFiltersProps {
  hasAppliedFilters?: boolean;
  selectedDateRange: IDateRange | null;
  initialDateRange?: ISimpleDateRange;
  setSelectedDateRange: Dispatch<SetStateAction<IDateRange>>;
  isLoading: boolean;
  applyFilters: (clearFilters?: boolean, userGroupId?: string) => void;
  selectedStatus: string;
  setSelectedStatus: (val: any) => void;
  selectedServiceType: string;
  selectedAssignedTo: string;
  setSelectedServiceType: (val: string) => void;
  setSelectedAssignedTo: (val: string) => void;
  setHasAppliedFilters: (val: boolean) => void;
  setSelectedSite?: (val: string) => void;
  selectedSite: string;
  selectedTab?: ServiceTypeTab | string;
  isFieldReport?: boolean;
  selectedUserGroup: string;
  defaultUserGroup: string;
  setSelectedUserGroup: (val: string) => void;
  setDefaultUserGroup: (val: string) => void;
  isRecurringServiceDetail?: boolean;
  hasQueryParamFiltering?: boolean;
}

export const ScheduledServicesFilters: FC<IScheduledServicesFiltersProps> = ({
  hasAppliedFilters,
  initialDateRange,
  selectedDateRange,
  setSelectedDateRange,
  selectedServiceType,
  setSelectedServiceType,
  isLoading,
  applyFilters,
  selectedStatus,
  setSelectedStatus,
  setHasAppliedFilters,
  setSelectedAssignedTo,
  selectedAssignedTo,
  selectedSite,
  setSelectedSite,
  selectedTab,
  isFieldReport,
  selectedUserGroup,
  defaultUserGroup,
  setSelectedUserGroup,
  setDefaultUserGroup,
  isRecurringServiceDetail,
  hasQueryParamFiltering,
}) => {
  const { user } = useContext(UserContext);
  const { paramUserGroupId } = useContext(SearchParamsContext);
  const localStorageUserGroupId = localStorage?.getItem(MAINTENANCE_FIELD_REPORT_USER_GROUP);
  const initialCompletedStartDate = new Date();
  const initialCompletedEndDate = new Date();
  const initialStartDate = !!initialDateRange ? initialDateRange?.selection?.startDate : null;
  const initialEndDate = !!initialDateRange ? initialDateRange?.selection?.endDate : null;
  const [assignToUsers, setAssignToUsers] = useState<IListUser[] | null>([]);
  const [serviceDefinitions, setServiceDefinitions] = useState<IServiceDefinition[] | null>([]);
  const [isLoadingDefinitions, setLoadingDefinitions] = useState<boolean>(false);
  const [siteOptions, setSiteOptions] = useState<ISiteSimple[]>([]);

  const fetchUsers = async () => {
    try {
      const res = await getUsers({ perPage: -1 });
      setAssignToUsers(res.records);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchServiceDefinitions = async () => {
    setLoadingDefinitions(true);
    try {
      const options: any = {
        sortDirection: 'asc',
        sortBy: 'Description',
        perPage: -1,
        officeId: user?.officeId as string,
      };
      const res = await getServiceDefinitions(options);
      setServiceDefinitions(res.records);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDefinitions(false);
    }
  };
  useEffect(() => {
    if (!isFieldReport) {
      fetchUsers();
      fetchServiceDefinitions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selected = siteOptions?.find(val => val.siteId === selectedSite);
  return (
    <Grid
      container
      spacing={2}
      className="print--none"
      onKeyDown={e => {
        checkForEnterKey(e, () => {
          setHasAppliedFilters(true);
          applyFilters();
        });
      }}
    >
      {!isFieldReport && (
        <>
          {setSelectedSite && !isRecurringServiceDetail && (
            <Grid item xs={12} sm={6} md={4}>
              <SiteAutocomplete
                handleChange={val => {
                  setSelectedSite(val);
                }}
                selectedSite={selected ?? null}
                siteId={selectedSite}
                isRequired={false}
                showInvalidAddress={false}
                handleOptions={sites => setSiteOptions(sites)}
                showSiteLink={false}
              />
            </Grid>
          )}
          {!isRecurringServiceDetail && (
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                select
                label="Service Type"
                size="small"
                onChange={({ target: { value } }) => {
                  setSelectedServiceType(value);
                }}
                value={selectedServiceType}
                disabled={isLoadingDefinitions}
                SelectProps={{
                  endAdornment: selectedServiceType && (
                    <InputAdornment
                      position="end"
                      sx={{
                        position: 'absolute',
                        right: 35,
                        cursor: 'pointer',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faClose}
                        title="Clear"
                        onClick={() => setSelectedServiceType('')}
                      />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  'data-testid': 'service-type-select',
                }}
              >
                {serviceDefinitions?.map((def, index) => {
                  return (
                    <MenuItem key={`${index}`} value={def.serviceDefId}>
                      {def.description}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          )}
          {selectedTab === 'O' && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  select
                  label="Status"
                  size="small"
                  onChange={({ target: { value } }) => {
                    setSelectedStatus(value);
                  }}
                  value={selectedStatus}
                  SelectProps={{
                    endAdornment: selectedStatus && (
                      <InputAdornment
                        position="end"
                        sx={{
                          position: 'absolute',
                          right: 35,
                          cursor: 'pointer',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faClose}
                          title="Clear"
                          onClick={() => setSelectedStatus('')}
                        />
                      </InputAdornment>
                    ),
                  }}
                  data-testid="status-select"
                >
                  {[
                    {
                      label: 'Open',
                      value: 'O',
                    },
                    {
                      label: 'Hold for Call',
                      value: 'H',
                    },
                    {
                      label: 'Hold for Parts',
                      value: 'P',
                    },
                    {
                      label: 'Closed',
                      value: 'C',
                    },
                  ].map((stat, index) => {
                    return (
                      <MenuItem key={`${index}`} value={stat.value}>
                        {stat.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </>
          )}
          {selectedTab && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <FilterWrapper>
                  <DatePicker
                    label="Start Date"
                    value={selectedDateRange?.startDate}
                    onChange={newValue => {
                      setSelectedDateRange({
                        ...selectedDateRange,
                        startDate: newValue as any,
                      } as IDateRange);
                    }}
                    slots={{
                      openPickerButton: selectedDateRange?.startDate
                        ? () => (
                            <div>
                              <InputAdornment
                                position="end"
                                sx={{
                                  position: 'absolute',
                                  right: 15,
                                  cursor: 'pointer',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faClose}
                                  title="Clear"
                                  onClick={() =>
                                    setSelectedDateRange({
                                      ...selectedDateRange,
                                      startDate: null,
                                    } as IDateRange)
                                  }
                                />
                              </InputAdornment>
                            </div>
                          )
                        : undefined,
                    }}
                    slotProps={{
                      textField: {
                        inputProps: {
                          'data-testid': 'start-date-field',
                        },
                        size: 'small',
                        fullWidth: true,
                      },
                    }}
                  />
                </FilterWrapper>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FilterWrapper>
                  <DatePicker
                    label="End Date"
                    value={selectedDateRange?.endDate}
                    onChange={newValue => {
                      setSelectedDateRange({
                        ...selectedDateRange,
                        endDate: newValue as Date,
                      } as IDateRange);
                    }}
                    minDate={selectedDateRange?.startDate}
                    slots={{
                      openPickerButton: selectedDateRange?.endDate
                        ? () => (
                            <div>
                              <InputAdornment
                                position="end"
                                sx={{
                                  position: 'absolute',
                                  right: 15,
                                  cursor: 'pointer',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faClose}
                                  title="Clear"
                                  onClick={() =>
                                    setSelectedDateRange({
                                      ...selectedDateRange,
                                      endDate: null,
                                    } as IDateRange)
                                  }
                                />
                              </InputAdornment>
                            </div>
                          )
                        : undefined,
                    }}
                    slotProps={{
                      textField: {
                        inputProps: {
                          'data-testid': 'end-date-field',
                        },
                        fullWidth: true,
                        size: 'small',
                      },
                    }}
                  />
                </FilterWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  select
                  label="Assigned To"
                  size="small"
                  onChange={({ target: { value } }) => {
                    setSelectedAssignedTo(value);
                  }}
                  value={selectedAssignedTo}
                  disabled={isLoadingDefinitions}
                  SelectProps={{
                    endAdornment: selectedAssignedTo && (
                      <InputAdornment
                        position="end"
                        sx={{
                          position: 'absolute',
                          right: 35,
                          cursor: 'pointer',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faClose}
                          title="Clear"
                          onClick={() => setSelectedAssignedTo('')}
                        />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    'data-testid': 'assigned-to-select',
                  }}
                >
                  {assignToUsers?.map((user, index) => {
                    return (
                      <MenuItem key={`${index}`} value={user.userId}>
                        {user.userName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </>
          )}
        </>
      )}
      {isFieldReport && (
        <Grid item xs={12} sm={6} md={4}>
          <SelectAsyncInput
            label="User Group"
            name="maintenanceUserGroup"
            value={selectedUserGroup}
            handleChange={value => {
              setSelectedUserGroup(value);
              localStorage.setItem(MAINTENANCE_FIELD_REPORT_USER_GROUP, value);
            }}
            apiRequest={() => {
              return getUserGroups({
                perPage: -1,
                officeId: user?.officeId,
                isForFieldReport: true,
              });
            }}
            handleResponseOptions={(response: IPaginatedResponse<IUserGroup>) => {
              if (paramUserGroupId) {
                return setSelectedUserGroup(paramUserGroupId);
              }
              if (localStorageUserGroupId) {
                return setSelectedUserGroup(localStorageUserGroupId);
              }
              setDefaultUserGroup(response?.data?.[0]?.userGroupId ?? '');
              setSelectedUserGroup(response?.data?.[0]?.userGroupId ?? '');
            }}
            transformResponse={(res: IPaginatedResponse<IUserGroup>) => {
              const resWithAll = [
                {
                  label: 'All',
                  value: 'all',
                },
                ...(res.data ?? []).map(r => ({
                  label: r.userGroupName,
                  value: r.userGroupId,
                })),
              ];
              return resWithAll;
            }}
            hasClear={false}
          />
        </Grid>
      )}
      {!isFieldReport && (
        <Grid item xs={12}>
          <Box display="flex" gap={1} justifyContent="flex-end">
            {hasAppliedFilters && (
              <Button
                size="small"
                color="inherit"
                disabled={isLoading}
                onClick={() => {
                  setHasAppliedFilters(false);
                  if (!hasQueryParamFiltering) {
                    setSelectedServiceType('');
                    setSelectedUserGroup && setSelectedUserGroup(defaultUserGroup ?? '');
                    setSelectedAssignedTo('');
                    setSelectedStatus(selectedTab === 'O' ? 'O' : 'C');
                    setSelectedSite && setSelectedSite('');
                    setSelectedDateRange({
                      startDate: selectedTab === 'O' ? initialStartDate : initialCompletedStartDate,
                      endDate: selectedTab === 'O' ? initialEndDate : initialCompletedEndDate,
                      key: 'selection',
                      inputValue: '',
                    });
                  }

                  applyFilters(true);
                }}
              >
                Reset
              </Button>
            )}
            <Button
              color="primary"
              size="small"
              disabled={isLoading}
              onClick={() => {
                setHasAppliedFilters(true);
                applyFilters();
              }}
              data-testid="apply-filters-button"
            >
              Apply Filters
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

const FilterWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

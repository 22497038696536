import { ROLES, Permissions } from '../constants';
import { IUser, INews, IRoute, ISubRoute } from '../models';
import { ISetupPageRoute } from '../pages/setup/setup-page-routes';

export const truncate = (input: string, maxLength: number) =>
  input.length > maxLength && maxLength >= 1 ? `${input.substring(0, maxLength)}...` : input;

export const alphaSort = (arr: any[], key: string) => {
  return arr.sort((a, b) => {
    const nameA = a?.[key]?.toUpperCase(); // ignore upper and lowercase
    const nameB = b?.[key]?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; //nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0; // names must be equal
  });
};

export const getAreaCode = (phoneNumber: string) => {
  return phoneNumber.replace(/\D/g, '').substr(0, 3);
};
export const removeAreaCode = (phoneNumber: string) => {
  return phoneNumber.substring(6);
};

// Converts a shallow object with null properties to empty strings
// Used with Formik and MUI since the form fields do not like null values
export const convertNullsToEmptyStrings = (obj: any) => {
  Object.keys(obj).forEach(property => {
    if (obj[property] === null) {
      obj[property] = '';
    }
  });
  return obj;
};

/**
 * HTML generated by Quill when the WYSIWYG editor is "blank"
 */
const emptyWysiwygHtml: string[] = [
  '<h1><br></h1>',
  '<h2><br></h2>',
  '<h3><br></h3>',
  '<h4><br></h4>',
  '<h5><br></h5>',
  '<h6><br></h6>',
  '<p><br></p>',
  '<h1> </h1>',
  '<h2> </h2>',
  '<h3> </h3>',
  '<h4> </h4>',
  '<h5> </h5>',
  '<h6> </h6>',
  '<p> </p>',
  '<h1></h1>',
  '<h2></h2>',
  '<h3></h3>',
  '<h4></h4>',
  '<h5></h5>',
  '<h6></h6>',
  '<p></p>',
];

/**
 * Check if Quill WYISWYG editor content is actually empty
 * @param content string | undefined
 * @returns boolean
 */
export const isWysiwygEmpty = (content?: string | null): boolean => {
  let empty = true;

  if (content) {
    content = content.trim();

    const html = emptyWysiwygHtml.find(emptyHtml => emptyHtml === content);
    if (!html) {
      empty = false;
    }
  }

  return empty;
};

export const getNumberRange = (start: number, stop: number, step: number): number[] =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

// Dropdown values for Day of Month select field
export const daysOfMonth: number[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31, 0,
];

export const daysOfMonthPayment: number[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 0,
];

export const hasNavActiveFlags = (link: IRoute | ISubRoute, flags: any) => {
  let showLink = true; // Return all links
  // If feature flags are set for link, then test the flags.
  if (link?.featureFlags && link?.featureFlags.length) {
    // Test if any of the flags are set to true. If so, show link, otherwise hide link.
    showLink = link?.featureFlags.some(flag => flags[flag] && flags[flag] === true) ?? false;
  }
  return showLink;
};

export const hasNavAccess = (link: IRoute | ISubRoute, user: IUser) => {
  // return all of the links
  let hasNeededRoles = true;

  if (link?.roles?.length! > 0 && user?.userType && user?.userType !== ROLES.Emulating) {
    hasNeededRoles = link?.roles?.includes(user?.userType) ?? false;
  }
  return hasNeededRoles;
};

export const hasCorrectUserPermissions = (permission: string, user: IUser): boolean => {
  return user?.userType === ROLES.Emulating
    ? true
    : user?.roles?.map(r => r.name).includes(permission) ?? false;
};

export const hasAppAccessPermission = (user: IUser): boolean => {
  return user?.userType === ROLES.Emulating || user?.userType === ROLES.SuperAdmin
    ? true
    : user?.roles?.map(r => r.name).includes(Permissions.DesktopApplicationAccess) ?? false;
};

export const hasNavPermissions = (link: IRoute | ISubRoute | ISetupPageRoute, user: IUser) => {
  let hasNeededPermissions = true;
  const permissions = user?.roles?.map(r => r.name) ?? [];

  if (link?.permissions?.length! > 0 && user?.userType !== ROLES.Emulating) {
    hasNeededPermissions = permissions.some(perm => link?.permissions?.includes(perm));
  }
  return hasNeededPermissions;
};

export const getRelativeLink = (url?: string): string =>
  !url?.startsWith('/') && !url?.startsWith('http') ? `/${url}` : url;

// Generates an HSL color value with a minimum distance between consecutive hues
export const makeColor = (colorNum: number, totalColors: number = 1): string => {
  // Define the minimum distance between consecutive hues
  const minHueDistance = 40;

  // Calculate the angular distance between consecutive hues
  const hueStep = Math.max(360 / totalColors, minHueDistance);

  // Calculate the base hue for the current color
  const baseHue = (colorNum * hueStep) % 360;

  // Set the saturation to a fixed value of 80%
  const saturation = 80;

  // Calculate the lightness based on the hue, with a special case for green and teal hues
  const lightness = baseHue > 150 && baseHue < 200 ? 50 - colorNum * 7 : 50 - colorNum * 2;

  // Generate and return the final HSL color value
  return `hsl(${baseHue}, ${saturation}%, ${lightness}%)`;
};

export const dateSortDesc = (arr: any[], key: string) => {
  return arr.sort((a, b) => {
    const nameA = new Date(a[key]); // convert to date
    const nameB = new Date(b[key]); // convert to date
    if (nameA > nameB) {
      return -1; //nameA comes first
    }
    if (nameA < nameB) {
      return 1; // nameB comes first
    }
    return 0; // names must be equal
  });
};
export const numberSortDesc = (arr: any[], key: string) => {
  return arr.sort((a, b) => {
    const itemA = a[key];
    const itemB = b[key];
    if (itemA > itemB) {
      return -1; //itemA comes first
    }
    if (itemA < itemB) {
      return 1; // itemB comes first
    }
    return 0;
  });
};

export const resolveObjectField = (path: string, obj: any) => {
  return path.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj);
};

export const convertToNumber = (input: number | string | null | undefined): number => {
  if (!input) {
    return 0;
  }

  if (typeof input === 'number') {
    return input;
  }

  if (typeof input === 'string') {
    const value = parseFloat(input.replace(/[^0-9.-]+/g, ''));
    if (isNaN(value)) {
      return 0;
    }
    return value;
  }

  return 0;
};

export const prioritizeNewsItems = (news: INews[]) => {
  let adminNews = news.filter(item => item.isCreatedByAdmin);
  let storeNews = news.filter(item => !item.isCreatedByAdmin);

  return [...adminNews, ...storeNews];
};

export const generateUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const removeHtmlArtifacts = (val: string): string => {
  return (
    val
      .replaceAll(/<\/?[^>]+(>|$)/g, '')
      .replaceAll('\r', '')
      .replaceAll('\n', '') ?? ''
  );
};

export const hyphenSeparateTwoInputs = (a?: string, b?: string): string => {
  return `${a ? a : ''}${a && b ? ' - ' : ''}${b ? b : ''}`;
};
export const getLocalPageSize = (key: string) => {
  const localPageSize = localStorage.getItem(key);
  return !!localPageSize ? Number(localPageSize) : 10;
};

// This is a type that can be used to define a variable that can be a string, number, or null
export type stringNumNull = string | number | null;

// This is a type that can be used to define a variable that can be a Date, string, or null
export type dateStringNull = Date | string | null;

// Groups an array of objects by the key specified
export const groupBy = (items: any[], key: string) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

// Accepts an array or single string and always returns an array
export const convertStringToArray = (param: string[] | string): string[] => {
  return typeof param === 'string' ? [param] : param;
};

export const convertCurrencyToNumber = (input: string | number | undefined) =>
  !input ? 0 : `${input}`.startsWith('$') ? +`${input}`.replace(/\$/g, '') : +input;

export const getDaySuffix = (num: number) => {
  let array = ('' + num).split('').reverse(); // E.g. 123 = array("3","2","1")

  if (array[1] !== '1') {
    // Number is not in the teens
    switch (array[0]) {
      case '1':
        return `${num}st`;
      case '2':
        return `${num}nd`;
      case '3':
        return `${num}rd`;
    }
  }

  return `${num}th`;
};

export const checkForEnterKey = (e: React.KeyboardEvent, callback: () => void) => {
  if (e.key === 'Enter') {
    callback();
  }
};

export const textToNumber = (text: string): string => {
  if (text === 'Hour') {
    return '1';
  }
  const startIndex = 0;
  const endIndex = text.indexOf('Minutes');
  const numberText = text.slice(startIndex, endIndex);
  if (numberText === 'Fifteen') {
    return '15';
  }
  return '30';
};

export const copyTextToClipboard = async (text: string) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
};
